var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, enums } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import styles from "./Th.module.scss";
var SORT_ICONS = {
    asc: enums.icons.DOWN,
    desc: enums.icons.UP,
    "": enums.icons.SWITCH
};
var Th = function (_a) {
    var children = _a.children, className = _a.className, isSortable = _a.isSortable, isHighlight = _a.isHighlight, _b = _a.order, order = _b === void 0 ? "" : _b, props = __rest(_a, ["children", "className", "isSortable", "isHighlight", "order"]);
    var icon = SORT_ICONS[order];
    return (_jsxs("th", __assign({ className: classNames(styles.th, isSortable && styles.isSortable, isHighlight && styles.isHighlight, className) }, props, { children: [children, isSortable && _jsx(Icon, { name: icon, size: enums.sizes.X3 }, void 0)] }), void 0));
};
export default Th;
