var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useState, useEffect, useMemo } from "react";
import styles from "./index.module.scss";
var StrengthGauge = function (_a) {
    var strength = _a.strength, _b = _a.i18n, i18n = _b === void 0 ? {
        t: function (text) {
            return text;
        }
    } : _b;
    var t = i18n.t;
    var STRENGTHS = useMemo(function () { return [
        { value: 0, type: "very-weak", text: t("very-weak") },
        { value: 1, type: "weak", text: t("weak") },
        { value: 2, type: "okay", text: t("okay") },
        { value: 3, type: "good", text: t("good") },
        { value: 4, type: "very-good", text: t("very-good") }
    ]; }, [t]);
    var _c = useState(STRENGTHS[0]), attrs = _c[0], setAttrs = _c[1];
    var type = attrs.type, text = attrs.text;
    useEffect(function () {
        var s = strength < 0 ? 0 : strength > 4 ? 4 : strength;
        setAttrs(STRENGTHS[s || 0]);
    }, [STRENGTHS, strength]);
    var mainClass = classNames(styles["ck-strength-gauge"], styles[type]);
    var barClass = styles["strength-bar"];
    var textClass = styles["strength-text"];
    return (_jsxs("div", __assign({ className: mainClass }, { children: [_jsx("div", { className: barClass }, void 0), _jsx("div", __assign({ className: textClass }, { children: text }), void 0)] }), void 0));
};
export default StrengthGauge;
