var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Message, enums } from "@cheetahdigital/console-kit-cx";
import { useCallback, useEffect, useState } from "react";
import Headline from "../../elements/Headline";
import styles from "./index.module.scss";
var Banner = function (_a) {
    var _b = _a.type, type = _b === void 0 ? enums.messageTypes.SUCCESS : _b, _c = _a.title, title = _c === void 0 ? "" : _c, _d = _a.description, description = _d === void 0 ? "" : _d, _e = _a.duration, duration = _e === void 0 ? 5000 : _e, _f = _a.onClose, onClose = _f === void 0 ? function () { return ({}); } : _f, children = _a.children;
    var _g = useState(false), isShow = _g[0], setIsShow = _g[1];
    var handleClose = useCallback(function () {
        setIsShow(false);
        onClose();
    }, [onClose]);
    useEffect(function () {
        setIsShow(true);
        if (!duration)
            return;
        var timer = setTimeout(function () {
            handleClose();
        }, duration);
        return function () { return clearTimeout(timer); };
    }, [duration, handleClose]);
    return (_jsx("div", __assign({ className: styles.banner }, { children: _jsx(Message, __assign({ messageType: type, isDisplayed: isShow, onClose: handleClose, isGrowler: true }, { children: _jsxs("div", { children: [title && _jsx(Headline, { children: title }, void 0), _jsx("p", { children: children || description }, void 0)] }, void 0) }), void 0) }), void 0));
};
export default Banner;
