var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, enums } from "@cheetahdigital/console-kit-cx";
import { useCallback, useState } from "react";
import { Modal, Headline, Buttons, Button } from "../..";
var ConfirmationModal = function (_a) {
    var title = _a.title, description = _a.description, onCancel = _a.onCancel, onOk = _a.onOk, _b = _a.i18n, i18n = _b === void 0 ? {
        t: function (text) {
            return text;
        }
    } : _b;
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var t = i18n.t;
    var handleYes = useCallback(function () {
        setIsLoading(true);
        onOk();
    }, [onOk]);
    return (_jsx(Modal, __assign({ isActive: true, onClose: onCancel }, { children: _jsxs(Layout, { children: [_jsx(Layout.Row, { children: _jsx(Headline, __assign({ size: "huge", isUppercase: false, isGapless: true }, { children: title }), void 0) }, void 0), _jsx(Layout.Row, { children: _jsx(Layout.Column, { children: description }, void 0) }, void 0), _jsx(Layout.Row, { children: _jsx(Layout.Column, __assign({ textAlign: enums.textAlignments.RIGHT }, { children: _jsxs(Buttons, { children: [_jsx(Button, __assign({ isSolid: false, onClick: onCancel }, { children: t("cancel") }), void 0), _jsx(Button, __assign({ isSolid: true, onClick: handleYes, isLoading: isLoading }, { children: t("yes") }), void 0)] }, void 0) }), void 0) }, void 0)] }, void 0) }), void 0));
};
export default ConfirmationModal;
