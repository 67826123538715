var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paging } from "@cheetahdigital/console-kit-cx";
import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import styles from "./index.module.scss";
import translations from "./translations.json";
var DEBOUNCE_TIMING = 500;
var MAX_WAIT = 2000;
var Pagination = function (_a) {
    var size = _a.size, total = _a.total, _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.locale, locale = _c === void 0 ? "en" : _c, onChange = _a.onChange;
    var t = translations[locale];
    var startPage = (!page && 1) || page + 1;
    var _d = useState(startPage), currentPage = _d[0], setCurrentPage = _d[1];
    var onChangeCallback = useCallback(function (value) {
        if (onChange && value > 0) {
            onChange(Math.max(value - 1, 0));
        }
    }, [onChange]);
    var handleCallback = useDebouncedCallback(onChangeCallback, DEBOUNCE_TIMING, {
        maxWait: MAX_WAIT
    });
    var handleChange = useCallback(function (e, _a) {
        var value = _a.value;
        setCurrentPage(value);
        handleCallback(value);
    }, [handleCallback]);
    return (_jsx("div", __assign({ className: styles.pagination }, { children: _jsx(Paging, { currentPage: currentPage, itemsPerPage: size, itemsTotal: total, pagePrefixLabel: t["page"], pageSuffixLabel: t["of-total"], displayLabel: t["displaying-from-total"], noDataLabel: t["no-data-to-display"], isDisabled: false, onChange: handleChange }, void 0) }), void 0));
};
export default Pagination;
