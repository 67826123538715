var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import styles from "./index.module.scss";
var PagingBullets = function (_a) {
    var pages = _a.pages, _b = _a.current, current = _b === void 0 ? 1 : _b;
    var mainClass = styles["ck-paging-bullets"];
    return (_jsx("ul", __assign({ className: mainClass }, { children: __spreadArray([], Array(pages), true).map(function (e, i) { return i; })
            .map(function (page) {
            var bulletClass = classNames(styles["bullet"], page + 1 < current ? styles.visited : "", page + 1 === current ? styles.active : "");
            return _jsx("li", { className: bulletClass }, page);
        }) }), void 0));
};
export default PagingBullets;
