var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SimplePopup, enums } from "@cheetahdigital/console-kit-cx";
import styles from "./index.module.scss";
var Dropdown = function (_a) {
    var trigger = _a.trigger, children = _a.children, _b = _a.position, position = _b === void 0 ? "bottom" : _b;
    return (_jsx(SimplePopup, __assign({ isBasic: true, showOn: "click", hideOnScroll: true, closeOnClick: true, style: { padding: 0, margin: 0 }, position: position ? position : enums.positions.BOTTOM, trigger: trigger }, { children: _jsx("div", __assign({ className: styles.container }, { children: children }), void 0) }), void 0));
};
export default Dropdown;
