var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import TagDelete from "../../elements/Tag/TagDelete";
import TagLabel from "../../elements/Tag/TagLabel";
import styles from "./index.module.scss";
var CreateableMultiTag = function (_a) {
    var placeholder = _a.placeholder, minHeight = _a.minHeight, isDisabled = _a.isDisabled, onChange = _a.onChange, tags = _a.tags, validation = _a.validation;
    var _b = useState(""), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState([]), value = _c[0], setValue = _c[1];
    var MultiValueRemove = function (_a) {
        var innerProps = _a.innerProps, data = _a.data;
        var isValidTag = validation(data === null || data === void 0 ? void 0 : data.value);
        var onClick = innerProps.onClick;
        return (_jsx(TagDelete, { className: classNames(styles.delete, !isValidTag && styles.error), onClick: onClick }, void 0));
    };
    var MultiValueLabel = function (_a) {
        var children = _a.children, data = _a.data;
        var value = data.value;
        var isValidTag = !!children && validation(children);
        return (_jsx(TagLabel, __assign({ color: !isValidTag ? "error" : "accent", title: value, size: "medium", className: classNames(styles.tag, !isValidTag && styles.error) }, { children: _jsx("div", __assign({ className: styles.multiValueContainer }, { children: children }), void 0) }), void 0));
    };
    useEffect(function () {
        var initialValue = tags
            ? tags.filter(function (e) { return !!e; }).map(function (tag) { return ({ label: tag, value: tag }); })
            : [];
        setValue(initialValue);
    }, [tags]);
    var selectStyles = {
        control: function (base) { return (__assign(__assign({}, base), { minHeight: minHeight, alignItems: "baseline", border: "1px solid #d4d9d9", borderRadius: "0.2rem", boxShadow: "none", "&:hover": {
                border: "1px solid #92dedd"
            } })); },
        multiValue: function (base) { return (__assign(__assign({}, base), { background: "none" })); },
        placeholder: function (base) {
            return __assign(__assign({}, base), { color: "#b2b8b8", "font-size": "1.5rem" });
        }
    };
    var handleChange = useCallback(function (values) {
        setValue(values);
        var tags = values.map(function (_a) {
            var value = _a.value;
            return value;
        });
        onChange(tags);
    }, [onChange]);
    var handleUpdateTags = useCallback(function (inputValue) {
        var uniqTags = Array.from(new Set(inputValue.split(/[ ,]+/)));
        var validTags = uniqTags.filter(function (tag) { return !!tag; });
        var inputValues = validTags.map(function (tag) { return ({
            label: tag,
            value: tag
        }); });
        var values = __spreadArray(__spreadArray([], value, true), inputValues, true);
        setInputValue("");
        setValue(values);
        handleChange(values);
    }, [handleChange, value]);
    var handleInputChange = useCallback(function (updatedInputValue) {
        if (updatedInputValue.includes(",")) {
            handleUpdateTags(updatedInputValue);
            return;
        }
        setInputValue(updatedInputValue);
    }, [handleUpdateTags]);
    var handleKeyDown = useCallback(function (event) {
        if (!inputValue) {
            return;
        }
        switch (event.key) {
            case "Enter":
            case ",":
            case "Tab": {
                handleUpdateTags(inputValue);
                event.preventDefault();
                break;
            }
        }
    }, [handleUpdateTags, inputValue]);
    var handleMenuClose = useCallback(function () {
        var value = inputValue;
        setInputValue(value);
    }, [inputValue]);
    return (_jsx(CreatableSelect, { isMulti: true, isDisabled: isDisabled, onChange: handleChange, onKeyDown: handleKeyDown, onInputChange: handleInputChange, onMenuClose: handleMenuClose, menuIsOpen: false, placeholder: placeholder, inputValue: inputValue, value: value, components: {
            MultiValueLabel: MultiValueLabel,
            MultiValueRemove: MultiValueRemove,
            IndicatorSeparator: function () { return null; },
            DropdownIndicator: function () { return null; },
            ClearIndicator: function () { return null; }
        }, styles: selectStyles }, void 0));
};
export default CreateableMultiTag;
