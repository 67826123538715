import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

const AppBackground: FC = ({ children }) => {
  const location = useLocation();
  const image = "url(./assets/images/background-dark.svg)";

  useEffect(() => {
    if (
      window.location.pathname.indexOf("/m") === 0 ||
      window.location.pathname.indexOf("/profile") === 0 ||
      window.location.pathname.indexOf("/adm") === 0
    ) {
      document.body.style.backgroundImage = "unset";
      return;
    }

    document.body.style.backgroundImage = image;
  }, [location]);

  return <>{children}</>;
};

export default AppBackground;
