import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { FC, HTMLAttributes, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ENV } from "../../../constants";

const oktaAuth = new OktaAuth({
  issuer: ENV.OKTA_AUTH_ISSUER,
  clientId: ENV.OKTA_AUTH_CLIENT_ID,
  redirectUri: "/login/callback",
  pkce: true
});

const OktaAuthProvider: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const handleAuthRequired = useCallback(() => {
    history.push("/login");
  }, [history]);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={handleAuthRequired}
    >
      {children}
    </Security>
  );
};

export default OktaAuthProvider;
