var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal as CKModal } from "@cheetahdigital/console-kit-cx";
import styles from "./index.module.scss";
var Modal = function (_a) {
    var isActive = _a.isActive, onClose = _a.onClose, children = _a.children;
    return (_jsx(CKModal, __assign({ isSolid: true, isTransparent: true, hasCloseIcon: true, isFullscreen: false, showModal: isActive, onClose: onClose }, { children: _jsx("div", __assign({ className: styles.modalContainer }, { children: children }), void 0) }), void 0));
};
export default Modal;
