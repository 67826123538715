import {
  isAuthenticated,
  startGetTokenLoop
} from "@cheetahdigital/cesauth-core-lib";
import { useTranslation } from "locales/i18n";
import { FC, useEffect, useState } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";

type InactiveEvent = {
  end: () => void;
  stay: () => void;
};

const Guard: FC = ({ children }) => {
  const [t] = useTranslation();

  const history = useHistory();
  const [isAllowed, setIsAllowed] = useState<boolean>();

  useEffect(() => {
    if (isAllowed) {
      return;
    }

    const handleCesAuthOnInactivityWarning = (
      event: CustomEvent<InactiveEvent>
    ) => {
      const { end, stay } = event?.detail;

      if (confirm(t("do-you-want-to-extend-your-session"))) {
        stay();
        return;
      }

      end();
    };

    (async () => {
      if (await isAuthenticated(true)) {
        startGetTokenLoop(false);
        setIsAllowed(true);
      }

      window.addEventListener(
        "cesAuthOnInactivityWarning",
        handleCesAuthOnInactivityWarning as EventListener
      );
    })();

    return () => {
      window.removeEventListener(
        "cesAuthOnInactivityWarning",
        handleCesAuthOnInactivityWarning as EventListener
      );
    };
  }, [history, isAllowed, t]);

  return <>{isAllowed && children}</>;
};

const CesAuthSecureRoute: FC<RouteProps> = ({ children, path, exact }) => {
  return (
    <Route path={path} exact={exact}>
      <Guard>{children}</Guard>
    </Route>
  );
};

export default CesAuthSecureRoute;
