var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Header as CKHeader } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import styles from "./index.module.scss";
var Headline = function (_a) {
    var children = _a.children, _b = _a.size, size = _b === void 0 ? "medium" : _b, _c = _a.weight, weight = _c === void 0 ? "regular" : _c, _d = _a.isUppercase, isUppercase = _d === void 0 ? false : _d, _e = _a.isGapless, isGapless = _e === void 0 ? true : _e, byline = _a.byline, className = _a.className;
    return (_jsx("div", __assign({ className: classNames(styles.header, isUppercase && styles.isUppercase, isGapless && styles.isGapless, className) }, { children: _jsx(CKHeader, { text: children, size: size, font: { weight: weight }, byline: byline }, void 0) }), void 0));
};
export default Headline;
