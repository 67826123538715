import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox as CKCheckbox } from "@cheetahdigital/console-kit-cx";
var Checkbox = function (_a) {
    var label = _a.label, value = _a.value, checked = _a.checked, onChange = _a.onChange, disabled = _a.disabled;
    return (_jsx(CKCheckbox, { isDisabled: disabled, value: value, label: label, gutterSize: "small", isChecked: checked, onChange: function (event, value) {
            onChange({
                value: value.value,
                isChecked: value.checked
            });
        } }, void 0));
};
export default Checkbox;
