var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import styles from "./index.module.scss";
var SimpleDropdownItem = function (_a) {
    var children = _a.children, href = _a.href, target = _a.target, isDisabled = _a.isDisabled, onClick = _a.onClick;
    return (_jsxs(_Fragment, { children: [isDisabled && (_jsx("div", __assign({ className: classNames(styles.menuLink, styles.isDisabled) }, { children: children }), void 0)), !isDisabled && (_jsx("a", __assign({ className: classNames(styles.menuLink), href: href, target: target, onClick: onClick }, { children: children }), void 0))] }, void 0));
};
export default SimpleDropdownItem;
