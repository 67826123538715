var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
var ImageCropper = function (_a, ref) {
    var children = _a.children, _b = _a.zoom, zoom = _b === void 0 ? 1 : _b, image = _a.image, width = _a.width, height = _a.height, isRounded = _a.isRounded, onZoom = _a.onZoom;
    var containeRef = useRef(null);
    var handleZoom = useCallback(function (event) {
        event.preventDefault();
        if (!onZoom) {
            return;
        }
        var ratio = zoom + event.deltaY * -0.01;
        onZoom(Math.min(Math.max(1, ratio), 3));
    }, [onZoom, zoom]);
    useEffect(function () {
        if (!containeRef.current) {
            return;
        }
        var cropContainer = containeRef.current;
        cropContainer.addEventListener("wheel", handleZoom, {
            passive: false
        });
        return function () {
            cropContainer.removeEventListener("wheel", handleZoom);
        };
    }, [containeRef, handleZoom]);
    return (_jsx("div", __assign({ ref: containeRef }, { children: _jsx(AvatarEditor, __assign({ ref: ref, image: image, width: width, height: height, borderRadius: (isRounded && width) || 0, color: [255, 255, 255, 0.6], scale: zoom, rotate: 0 }, { children: children }), void 0) }), void 0));
};
export default forwardRef(ImageCropper);
