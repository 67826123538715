import cesTrack from "@cheetahdigital/ces-track";
import {
  NavbarProvider,
  NavbarWithoutProvider,
  UserProvider,
  OrganizationProvider,
  ChangeBusinessUnitModalProvider,
  useUserContext,
  useOrganizationContext,
  AppProvider,
  BusinessUnitChangeModalProvider
} from "@cheetahdigital/nav-kit-cx";
import { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ENV } from "../../../constants";

type AppType = "profile" | "admin" | "management";

type AppSetting = {
  href: string;
  name: string;
  icon: string;
  isShowChangeBusinessUnit: boolean;
};

export type NavbarProps = {
  app: AppType;
  links?: ReactNode;
};

type NavbarInnerProps = NavbarProps & {
  appSetting: AppSetting;
};

const APPS_SETTINGS: Record<AppType, AppSetting> = {
  profile: {
    href: "/profile",
    name: "Cheetah ID",
    icon: "/assets/images/cheetah-id.png",
    isShowChangeBusinessUnit: true
  },
  admin: {
    href: "/adm/organization",
    name: "CES Administration",
    icon: "/assets/images/admin.png",
    isShowChangeBusinessUnit: true
  },
  management: {
    href: "/m",
    name: "CES Management",
    icon: "/assets/images/management.png",
    isShowChangeBusinessUnit: true
  }
};

const NavbarInner: FC<NavbarInnerProps> = ({
  app,
  links,
  children,
  appSetting
}) => {
  const { href, name, icon, isShowChangeBusinessUnit } = appSetting;
  const { user, isSysAdmin, isCesAdmin } = useUserContext();
  const { id, firstName, lastName, email } = user;

  const { organization } = useOrganizationContext();
  const { orgName, buName } = organization;

  const location = useLocation();

  useEffect(() => {
    cesTrack.page();
  }, [location.pathname]);

  useEffect(() => {
    if (firstName && lastName && email && orgName && buName) {
      cesTrack.identify({
        identifiable: {
          email: email,
          name: firstName + " " + lastName,
          bu: buName,
          org: orgName
        },
        appInfo: {
          name: "CID",
          serverRegion: "AWS"
        }
      });
    }
  }, [firstName, lastName, email, orgName, buName]);

  const isNavbar =
    (app === "management" && !!id && isSysAdmin) ||
    (app === "admin" && !!id && isCesAdmin) ||
    app === "profile";

  return (
    <>
      {isNavbar && (
        <NavbarWithoutProvider
          customLogoHref={href}
          customAppName={name}
          customAppIcon={icon}
          isShowChangeBusinessUnit={isShowChangeBusinessUnit}
          isProfileOpenNewTab={false}
        >
          {links}
        </NavbarWithoutProvider>
      )}
      {children}
    </>
  );
};

const Navbar: FC<NavbarProps> = props => {
  const { app } = props;
  const appSetting = APPS_SETTINGS[app];

  return (
    <NavbarProvider
      authApiBaseUrl={ENV.API_CES_AUTH}
      authUiBaseUrl={ENV.UI_CES_AUTH}
    >
      <UserProvider authApiBaseUrl={ENV.API_CES_AUTH}>
        <OrganizationProvider authApiBaseUrl={ENV.API_CES_AUTH}>
          <AppProvider authApiBaseUrl={ENV.API_CES_AUTH}>
            <ChangeBusinessUnitModalProvider>
              <BusinessUnitChangeModalProvider
                authApiBaseUrl={ENV.API_CES_AUTH}
              >
                <NavbarInner {...props} appSetting={appSetting} />
              </BusinessUnitChangeModalProvider>
            </ChangeBusinessUnitModalProvider>
          </AppProvider>
        </OrganizationProvider>
      </UserProvider>
    </NavbarProvider>
  );
};

export default Navbar;
