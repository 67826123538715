var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useCallback } from "react";
import Table from "../../elements/Table";
import Td from "../../elements/Table/Td";
import Th from "../../elements/Table/Th";
import Tr from "../../elements/Table/Tr";
import Pagination from "../Pagination";
import styles from "./index.module.scss";
var Datatable = function (_a) {
    var className = _a.className, columns = _a.columns, rows = _a.rows, isSelectable = _a.isSelectable, isHeaderless = _a.isHeaderless, _b = _a.isHoverable, isHoverable = _b === void 0 ? true : _b, _c = _a.isPaginate, isPaginate = _c === void 0 ? true : _c, isBorderless = _a.isBorderless, isFullwidth = _a.isFullwidth, sortField = _a.sortField, sortOrder = _a.sortOrder, onSort = _a.onSort, onSelect = _a.onSelect, onSelectAll = _a.onSelectAll, onPageChange = _a.onPageChange, _d = _a.size, size = _d === void 0 ? 20 : _d, total = _a.total, page = _a.page, locale = _a.locale, props = __rest(_a, ["className", "columns", "rows", "isSelectable", "isHeaderless", "isHoverable", "isPaginate", "isBorderless", "isFullwidth", "sortField", "sortOrder", "onSort", "onSelect", "onSelectAll", "onPageChange", "size", "total", "page", "locale"]);
    var hasRow = (rows === null || rows === void 0 ? void 0 : rows.length) > 0;
    var isSelectAll = hasRow && !(rows === null || rows === void 0 ? void 0 : rows.some(function (_a) {
        var isSelected = _a.isSelected;
        return !isSelected;
    }));
    var isSelectSome = hasRow && (rows === null || rows === void 0 ? void 0 : rows.some(function (_a) {
        var isSelected = _a.isSelected;
        return isSelected;
    }));
    var isIndeterminate = !isSelectAll && isSelectSome;
    var enabledColumns = columns.filter(function (_a) {
        var isDisabled = _a.isDisabled;
        return !isDisabled;
    });
    var handleSort = useCallback(function (field) {
        if (!onSort) {
            return;
        }
        if (sortField !== field) {
            onSort({
                field: field,
                order: "asc"
            });
            return;
        }
        onSort({ field: field, order: sortOrder === "asc" ? "desc" : "asc" });
    }, [onSort, sortField, sortOrder]);
    var handleSelectAll = useCallback(function () {
        if (isIndeterminate) {
            onSelectAll && onSelectAll(false);
            return;
        }
        onSelectAll && onSelectAll(!isSelectAll);
    }, [onSelectAll, isIndeterminate, isSelectAll]);
    return (_jsxs("div", __assign({ "data-test": "datatable", className: classNames(styles.datatable, isFullwidth && styles.isFullwidth, className) }, props, { children: [_jsx(Table, __assign({ isFullwidth: true, thead: !isHeaderless && (_jsx(Tr, __assign({ isCheckable: isSelectable, isHoverable: isHoverable, indeterminate: isIndeterminate, checked: isSelectAll, onChange: handleSelectAll }, { children: enabledColumns.map(function (_a) {
                        var isSortable = _a.isSortable, field = _a.field, label = _a.label;
                        return (_jsx(Th, __assign({ isSortable: isSortable, order: sortField === field ? sortOrder : "", isHighlight: field === sortField, onClick: function () { return isSortable && handleSort(field); } }, { children: label }), field));
                    }) }), void 0)) }, { children: rows === null || rows === void 0 ? void 0 : rows.map(function (row) {
                    var isSelected = Boolean(row.isSelected);
                    var isHidden = Boolean(row.isHidden);
                    var isDisabled = Boolean(row.isDisabled);
                    var key = String(row.key);
                    return (!isHidden && (_jsx(Tr, __assign({ isDisabled: isDisabled, isCheckable: isSelectable, isHoverable: isHoverable, checked: isSelected, onChange: function () {
                            return onSelect && onSelect(__assign(__assign({}, row), { isSelected: !isSelected }));
                        } }, { children: enabledColumns === null || enabledColumns === void 0 ? void 0 : enabledColumns.map(function (_a) {
                            var isFullwidth = _a.isFullwidth, isNowrap = _a.isNowrap, field = _a.field, width = _a.width;
                            return (_jsx(Td, __assign({ isBorderless: isBorderless, isFullwidth: isFullwidth, isNowrap: isNowrap, width: width }, { children: row[field] || "" }), field));
                        }) }), key)));
                }) }), void 0), isPaginate && (_jsx(Pagination, { size: size, total: total, onChange: onPageChange, page: page, locale: locale }, void 0))] }), void 0));
};
export default Datatable;
