import i18n from "i18next";
import { mapObjIndexed } from "ramda";
import {
  initReactI18next,
  useTranslation as useI18nTranslation,
  UseTranslationResponse
} from "react-i18next";
import { Cookie } from "shared";
import { getLocale } from "utils/hooks/useLocale";

import de from "./translations/de.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import fr from "./translations/fr.json";
import ja from "./translations/ja.json";
import pt from "./translations/pt.json";

export type DefaultNS = keyof typeof resources.en.default;

export const defaultNS = "default";

const isDebug = Cookie.getCookie("debug");

const formatTranslation = (translations): typeof en => {
  if (isDebug) {
    return mapObjIndexed(translation => `👉${translation}`)(
      translations
    ) as typeof en;
  }

  return translations;
};

export const resources = {
  de: {
    default: formatTranslation(de)
  },
  en: {
    default: formatTranslation(en)
  },
  es: {
    default: formatTranslation(es)
  },
  fr: {
    default: formatTranslation(fr)
  },
  ja: {
    default: formatTranslation(ja)
  },
  pt: {
    default: formatTranslation(pt)
  }
} as const;

const { language } = getLocale();

export const i18Instance = i18n.createInstance();

i18Instance.use(initReactI18next).init({
  lng: language,
  ns: [defaultNS],
  defaultNS: defaultNS,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  resources
});

export const useTranslation = (): UseTranslationResponse<
  "default",
  undefined
> => useI18nTranslation(defaultNS, { i18n: i18Instance });

export default i18Instance;
