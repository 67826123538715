var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DateTimePicker as CKDateTimePicker } from "@cheetahdigital/console-kit-cx";
import styles from "./index.module.scss";
var browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
var DateTimePicker = function (_a) {
    var value = _a.value, time = _a.time, label = _a.label, min = _a.min, max = _a.max, timeZone = _a.timeZone, onSelect = _a.onSelect, disabled = _a.disabled;
    return (_jsx("div", __assign({ className: styles.datetimePicker }, { children: _jsx(CKDateTimePicker, { isDisabled: disabled, value: value, calendar: true, time: time, label: label, min: min, max: max, isFluid: true, timeZone: timeZone || browserTimeZone, initialView: "month", finalView: "month", onSelect: function (event, data) {
                if (data.value) {
                    onSelect(data.value);
                }
            }, onChange: function (event, data) {
                var value = data.value === "Invalid date" ? "" : data.value;
                onSelect(value);
            } }, void 0) }), void 0));
};
export default DateTimePicker;
