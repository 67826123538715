import { useCallback, useEffect, useState } from "react";
var EVENT_LOCALSTORAGE = "local-storage";
function useLocalStorage(key, initialValue) {
    var readValue = useCallback(function () {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            var item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            console.warn("Error reading localStorage key \u201C" + key + "\u201D:", error);
            return initialValue;
        }
    }, [initialValue, key]);
    var _a = useState(readValue), storedValue = _a[0], setStoredValue = _a[1];
    var setValue = function (value) {
        if (typeof window == "undefined") {
            console.warn("Tried setting localStorage key \u201C" + key + "\u201D even though environment is not a client");
        }
        try {
            var newValue = value instanceof Function ? value(storedValue) : value;
            window.localStorage.setItem(key, JSON.stringify(newValue));
            setStoredValue(newValue);
            window.dispatchEvent(new Event(EVENT_LOCALSTORAGE));
        }
        catch (error) {
            console.warn("Error setting localStorage key \u201C" + key + "\u201D:", error);
        }
    };
    var removeKey = useCallback(function () {
        try {
            window.localStorage.removeItem(key);
        }
        catch (error) {
            console.warn("Error remove localStorage key \u201C" + key + "\u201D:", error);
        }
    }, [key]);
    useEffect(function () {
        setStoredValue(readValue());
    }, [readValue]);
    useEffect(function () {
        var handleStorageChange = function () {
            setStoredValue(readValue());
        };
        window.addEventListener("storage", handleStorageChange);
        window.addEventListener(EVENT_LOCALSTORAGE, handleStorageChange);
        return function () {
            window.removeEventListener("storage", handleStorageChange);
            window.removeEventListener(EVENT_LOCALSTORAGE, handleStorageChange);
        };
    }, [readValue]);
    return [storedValue, setValue, removeKey];
}
export default useLocalStorage;
