var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
var ORIGINAL_DATA = "data-original";
var handleEllipse = function (parentNode, childNode, txtNode) {
    var childWidth = childNode.offsetWidth;
    var containerWidth = parentNode.offsetWidth;
    var txtWidth = txtNode.offsetWidth;
    var targetWidth = childWidth > txtWidth ? childWidth : txtWidth;
    if (targetWidth > containerWidth) {
        var str = txtNode.textContent;
        var txtChars = str.length;
        var avgLetterSize = txtWidth / txtChars;
        var canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
        var delEachSide = (txtChars - canFit + 5) / 2;
        var endLeft = Math.floor(txtChars / 2 - delEachSide);
        var startRight = Math.ceil(txtChars / 2 + delEachSide);
        txtNode.setAttribute(ORIGINAL_DATA, txtNode.textContent);
        txtNode.textContent = str.substr(0, endLeft) + "..." + str.substr(startRight);
    }
};
var CenterEllipsis = function (_a) {
    var width = _a.width, children = _a.children;
    var ref = useRef(null);
    useEffect(function () {
        var node = ref === null || ref === void 0 ? void 0 : ref.current;
        if (!node) {
            return;
        }
        var handlePrepEllipse = function () {
            var parent = node.parentNode;
            var child = node.childNodes[0];
            var txtToEllipse = (parent === null || parent === void 0 ? void 0 : parent.querySelector(".ellipseMe")) || child;
            if (child !== null && txtToEllipse !== null) {
                if (txtToEllipse.hasAttribute(ORIGINAL_DATA)) {
                    txtToEllipse.textContent = txtToEllipse.getAttribute(ORIGINAL_DATA);
                }
                handleEllipse(node.offsetWidth > parent.offsetWidth ? parent : node, child, txtToEllipse);
            }
        };
        window.addEventListener("resize", handlePrepEllipse);
        handlePrepEllipse();
        return function () {
            document.removeEventListener("resize", handlePrepEllipse);
        };
    }, [ref]);
    return (_jsx("div", __assign({ ref: ref, style: __assign({ wordBreak: "keep-all", overflowWrap: "normal" }, (width && { width: width })) }, { children: _jsx("span", { children: children }, void 0) }), void 0));
};
export default CenterEllipsis;
