var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from "@cheetahdigital/console-kit-cx";
import { useCallback, useState } from "react";
import Tooltip from "../../../components/Tooltip";
import Clickable from "../../../elements/Clickable";
import styles from "../index.module.scss";
var EyeIcon = function (_a) {
    var _b = _a.onClick, onClick = _b === void 0 ? function () { return ({}); } : _b, _c = _a.i18n, i18n = _c === void 0 ? {
        t: function (text) {
            return text;
        }
    } : _c;
    var _d = useState(false), isShowing = _d[0], setIsShowing = _d[1];
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        var shown = !isShowing;
        setIsShowing(shown);
        if (onClick) {
            onClick(shown);
        }
    }, [isShowing, onClick]);
    return (_jsx(Tooltip, __assign({ isCk: true, content: isShowing ? i18n === null || i18n === void 0 ? void 0 : i18n.t("hide") : i18n === null || i18n === void 0 ? void 0 : i18n.t("show"), position: "top left", color: "base" }, { children: _jsx(Clickable, __assign({ onClick: handleClick }, { children: _jsx("div", __assign({ className: styles.actionIcon }, { children: _jsx(Icon, { name: "preview" + (isShowing ? "" : "-off") }, void 0) }), void 0) }), void 0) }), void 0));
};
export default EyeIcon;
