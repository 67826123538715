var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Tooltip from "../../components/Tooltip";
import ButtonText from "../../elements/ButtonText";
import Checkbox from "../../form/Checkbox";
import Column from "../../layout/Column";
import Layout from "../../layout/Layout";
import Row from "../../layout/Row";
import styles from "./index.module.scss";
import translations from "./translations.json";
var Checkboxes = function (_a) {
    var options = _a.options, _b = _a.defaultSize, defaultSize = _b === void 0 ? 10 : _b, onChange = _a.onChange, disabled = _a.disabled, _c = _a.locale, locale = _c === void 0 ? "en" : _c;
    var t = translations[locale];
    var getSelectionOption = function (value) {
        return options.filter(function (option) { return option.value === value; })[0];
    };
    var handleCheckboxChange = function (_a) {
        var value = _a.value, isChecked = _a.isChecked;
        var selectedOption = getSelectionOption(value);
        var updatedSelectedOption = __assign(__assign({}, selectedOption), { isSelected: isChecked });
        onChange(updatedSelectedOption);
    };
    var total = options.length;
    var _d = useState(defaultSize), size = _d[0], setSize = _d[1];
    var shown = options.length - options.filter(function (_a) {
        var isHidden = _a.isHidden;
        return isHidden;
    }).length;
    var isPaginate = shown > defaultSize;
    var moreCount = shown - size;
    var isCollapsed = size <= total;
    var notHiddenOptions = options.filter(function (_a) {
        var isHidden = _a.isHidden;
        return !isHidden;
    });
    var sliced = isPaginate
        ? notHiddenOptions.slice(0, size)
        : notHiddenOptions;
    var isMore = isPaginate && isCollapsed;
    var isLess = isPaginate && !isCollapsed;
    return (_jsxs("div", __assign({ "data-test": "checkboxes", className: styles.checkboxes }, { children: [sliced.map(function (_a) {
                var label = _a.label, value = _a.value, isSelected = _a.isSelected, isHidden = _a.isHidden, isDisabled = _a.isDisabled;
                return (_jsx(_Fragment, { children: !isHidden && (_jsx(Tooltip, __assign({ content: label, position: "bottom center" }, { children: _jsx("div", __assign({ className: styles.content }, { children: _jsx(Checkbox, { label: label, value: value, checked: isSelected, onChange: handleCheckboxChange, disabled: disabled || isDisabled }, void 0) }), void 0) }), value)) }, void 0));
            }), isPaginate && (_jsx(Layout, { children: _jsxs(Row, { children: [_jsx(Column, {}, void 0), _jsxs(Column, __assign({ isNarrow: true, className: styles.paginateButtonColumn }, { children: [isMore && (_jsxs(ButtonText, __assign({ onClick: function () { return setSize(size + defaultSize); } }, { children: [t.more, " ", moreCount > 0 && "(" + moreCount + ")"] }), void 0)), isLess && (_jsx(ButtonText, __assign({ onClick: function () { return setSize(defaultSize); } }, { children: t.less }), void 0))] }), void 0)] }, void 0) }, void 0))] }), void 0));
};
export default Checkboxes;
