import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useContext
} from "react";

const initialDispatch = () => ({});

export const AuthContainerContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, initialDispatch]);

const AuthContainerProvider: FC = ({ children }) => {
  const context = useState(false);

  return (
    <AuthContainerContext.Provider value={context}>
      {children}
    </AuthContainerContext.Provider>
  );
};

export const useAuthContainerContext = (): {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
} => {
  const context = useContext(AuthContainerContext);
  const [, setIsLoading] = context;

  if (setIsLoading === initialDispatch) {
    throw new Error(
      "useAuthContainerContext must be used within a AuthContainerProvider"
    );
  }

  return {
    setIsLoading
  };
};

export default AuthContainerProvider;
