var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TabMenu, enums } from "@cheetahdigital/console-kit-cx";
import styles from "./index.module.scss";
var Tabs = function (_a) {
    var items = _a.items, active = _a.active, onChange = _a.onChange;
    var itemsWithIndex = items.map(function (item, index) {
        return __assign(__assign({}, item), { value: index });
    });
    return (_jsx("div", __assign({ className: styles.tabs }, { children: _jsx(TabMenu, { activeValue: active, items: itemsWithIndex, float: enums.positions.LEFT, isVertical: false, isFluid: true, onItemClick: function (e, item) {
                onChange(itemsWithIndex[item.value].key);
            } }, void 0) }), void 0));
};
export default Tabs;
